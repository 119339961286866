
import { default as React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { DataTablePagination } from "@/components/ui/table-pagination";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
export const ReportInvoiceTable = (props) => {
  const { reportId, fetchFunction, cacheKey } = props;
  const { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  const columns = [
    {
      accessorKey: "id",
      header: t("invoices:document"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"block w-full h-full"}>
            {row.original.invoiceId}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => (
        <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>

          {row.original.client.firstName} {row.original.client.lastName}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "counterparty",
      header: t("common:counterparty"),
      cell: ({ row }) => (
        <div className="capitalize">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {row.original.counterparty}
          </Link>
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "date of creation",
      header: t("invoices:date_of_creation"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.createdAt), 'dd.MM.yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "date of sale",
      header: t("invoices:date_of_sale"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.domainDate), 'dd.MM.yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "total brutto",
      header: t("invoices:price_brutto"),
      cell: ({ row }) => {
        return <div className="inline-block	w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {parseFloat(row.original.invoiceBrutto).toFixed(2)} {row.original.paymentCurrency}
          </Link>
        </div>
      },
      key: uuidv4()
    }
  ];
  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
  }, [pagination]);

  const { data: transactionsData, isLoading } = useQuery({
    queryKey: ['t_____', 'invoices___', reportId, page, cacheKey],
    retry: false,
    queryFn: () => {
      return fetchFunction(reportId, rowsPerPage, page * rowsPerPage)
    }
  });

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : transactionsData?.data.invoices,
    columns: isLoading ? columns.map(column => {
      return {
        ...column,
        cell: () => <Skeleton className={"h-4 w-[150px]"} />
      }
    }) : columns,
    rowCount: transactionsData?.data.total ?? 0,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination
    },
    initialState: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative">
        <div className="sticky left-0  z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between  pr-8 items-center bg-grayLightMainBg">
            <div className="flex justify-end w-5/6">
            </div>
          </div>
        </div>
        <div className="w-full bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        {t('no_results')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );
}
