import React, { useEffect, useState } from "react";
import AppBarForLogin from "../components/app-bar-for-main-login-pages/AppBarForLogin";
import '../scssStyles/SurveyStep4.scss';
import { EDUCATION_TYPE, OPTIONS_LANGUAGE, OPTIONS_STUDY_FIELD, OPTIONS_LANGUAGE_LEVEL, EDUCATIONS_TYPES, STUDY_FIELDS } from "../constants";
import { getUser, surveyStep4 } from "../service/weexpertService";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { Combobox } from "@/components/ui/combobox";
import { AlertCircle, DeleteIcon, TrashIcon } from "lucide-react";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { v4 } from 'uuid'
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";
import { toast } from "@/components/ui/use-toast";

const LanguageAbility = ({ onLanguageChange, onLanguageDelete, languageEntry }) => {

    const { t } = useTranslation();
    const { id, proficiency, language } = languageEntry;
    const [level, setLevel] = useState(proficiency);
    const [currentLanguage, setCurrentLangauge] = useState(language);

    useEffect(() => {
        onLanguageChange(id, currentLanguage, level);
    }, [currentLanguage, level])

    return <div key={id} >
        <div className="flex justify-between">
            <Combobox
                className={'w-1/2 mb-2'}
                label={t('survey:label_language')}
                value={currentLanguage}
                placeholder={t('survey:placeholder_languages')}
                creatable
                options={OPTIONS_LANGUAGE().map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })}
                onChange={(newValue) => {
                    setCurrentLangauge(newValue)
                }}
            />
            <Combobox
                className={'w-1/3 mb-2'}
                label={t('survey:label_knowledge')}
                value={level}
                placeholder={t('survey:label_knowledge')}
                options={OPTIONS_LANGUAGE_LEVEL().map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })}
                onChange={(newValue) => {
                    setLevel(newValue)
                }}
            />
            <div
                className={"flex items-center justify-center"}
                onClick={() => onLanguageDelete(id)}
            >
                <TrashIcon />
            </div>
        </div>
    </div>
}

export const SurveyStep4 = (props) => {

    const { back, next, updateState } = props
    const { t } = useTranslation();
    const onLanguageUpdate = (id, language, proficiency) => {
        if (languagesData.some(l => l.language && l.language?.value === language?.value && id != l.id)) {
            toast({
                title: t('survey:error_duplicate_language'),
                variant: "destructive"
            })
        }

        setLanguagesData((prevState) => {
            return prevState.map(e => {
                if (e.id == id) {
                    return {
                        id: id,
                        language,
                        proficiency
                    }
                }
                return e;
            })
        })
    }
    const onLanguageDelete = (id) => {
        setLanguagesData((prevState) => {
            return prevState.filter(e => e.id != id);
        })
    }

    const { data: userData, isLoading: loading } = useQuery({ queryKey: ['userMeProfile'], queryFn: getUser, retry: false, refetchOnWindowFocus: false })
    const [languagesData, setLanguagesData] = useState([]);
    const [inputChecked, setInputChecked] = useState(false);
    const [selectedEducation, setSelectedEducation] = useState();
    const [selectedStudy, setSelectedStudy] = useState();

    const checkErrorLanguage = () => {
        return inputChecked && languagesData.some(item => (!item.language && !item.proficiency));
    }

    const languageItem = languagesData.map((item) => (
        <div key={item.id}>
            <LanguageAbility
                languageEntry={item}
                onLanguageChange={onLanguageUpdate}
                onLanguageDelete={onLanguageDelete}
            />
        </div>
    ))

    const addNextLanguageKnowleges = () => {
        setLanguagesData((prev) => prev.concat({
            id: prev.length + 1,
            language: null,
            proficiency: null
        }));
    }


    useEffect(() => {
        if (!loading) {
            const { clientProfile } = userData.data;
            setSelectedStudy(OPTIONS_STUDY_FIELD()
                .filter(q => q.id == clientProfile.fieldOfStudy).map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })[0]
            )
            setSelectedEducation(EDUCATION_TYPE()
                .filter(q => q.id == clientProfile.educationLevel).map(e => {
                    return {
                        value: e.id,
                        label: e.value
                    }
                })[0]
            )
            const clientsSavedLanguages = clientProfile.languages.map(e => {
                const a = {
                    id: e.id,
                    language: {
                        value: OPTIONS_LANGUAGE().filter(p => p.id == e.code)[0]?.id ?? v4(),
                        label: e.name
                    },
                    proficiency:
                        OPTIONS_LANGUAGE_LEVEL().filter(p => p.id == e.proficiency).map(s => {
                            return {
                                value: s.id,
                                label: s.value
                            }
                        })[0] ?? null,
                }
                return a;
            })
            setLanguagesData(clientsSavedLanguages.length != 0 ? clientsSavedLanguages : [{ id: 1, language: null, proficiency: null }])
        }
    }, [userData])
    const onSaveStates = () => {
        setInputChecked(true)
        if (!selectedEducation) {
            return;
        }
        if (!selectedStudy) {
            return;
        }
        if (languagesData.length < 1 || languagesData.some(e => e.proficiency == null || e.language == null)) {
            return;
        }
        const uniqueValues = new Set(languagesData.map(v => v.language.value));
        if ((uniqueValues.size < languagesData.length)) {
            toast({
                title: t('survey:error_duplicate_language'),
                variant: "destructive"
            })
            return
        }

        surveyStep4(
            selectedEducation.value,
            selectedStudy.value,
            languagesData.map(e => {
                return {
                    code: e.language.value,
                    proficiency: e.proficiency.value
                }
            }))
            .then(response => {
                next('stage_4');
                //updateState()
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    console.error('Ошибка 403: Доступ запрещен');
                } else {
                    console.error('Произошла ошибка при получении пользователя:', error.message);
                }
            }
            );
    }
    if (loading) {
        return <Loader />
    }
    return (
        <>
            <div className={'container'}>
                <AppBarForLogin isLogined={true} userData={userData.data} />
                <div className="pt-24 pb-28 " >
                    <div className="text-3xl font-semibold w-2/3 pb-6">
                        {t('survey:step_4_capture')}
                    </div>
                    <div className="pb-6 text-base">
                        {t('survey:step_4_description')}
                    </div>
                    <div className="w-6/12 mb-4">
                        <Combobox
                            className={"my-2"}
                            key={selectedEducation?.value}
                            label={t('survey:label_education')}
                            placeholder={t('survey:label_education')}
                            creatable
                            value={selectedEducation}
                            error={inputChecked && !selectedEducation}
                            errorMessage={t('survey:error_education')}
                            options={EDUCATION_TYPE().map(e => {
                                return {
                                    value: e.id,
                                    label: e.value
                                }
                            })}
                            onChange={(newValue) => {
                                setSelectedEducation(newValue)
                            }}
                        />
                        <Combobox
                            className={"my-2"}
                            label={t('survey:label_study')}
                            value={selectedStudy}
                            key={selectedStudy?.value}
                            placeholder={t('survey:label_study')}
                            creatable
                            error={inputChecked && !selectedStudy}
                            errorMessage={t('survey:error_study_type')}
                            options={OPTIONS_STUDY_FIELD().map(e => {
                                return {
                                    value: e.id,
                                    label: e.value
                                }
                            })}
                            onChange={(newValue) => {
                                setSelectedStudy(newValue)
                            }}

                        />
                        <div className="knowlege-of-language-wrapper">
                            {languageItem}
                        </div>
                        {checkErrorLanguage() && (<div className="mt-1">
                            <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                                <AlertCircle className="h-4 w-4 !static !mr-2" />
                                <AlertTitle className="m-0 !p-0">
                                    {t('empty_data_invalid')}
                                </AlertTitle>
                            </Alert>
                        </div>)
                        }
                        <Button onClick={() => { addNextLanguageKnowleges(languagesData); }}>+</Button>
                    </div>
                </div>
                <Progress value={75} />
                <div className=" flex flex-row justify-between	 pt-6">
                    <Button className='w-36' variant="outline" onClick={() => back()}>{t('survey:button_back')}</Button>
                    <Button className='w-36' onClick={() => onSaveStates()}>{t('survey:button_next')}</Button>
                </div>
            </div >
        </>
    );
}
