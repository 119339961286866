
export const transformFromIdValueToValueLabel = (i) => {
  return {
    value: i.id,
    label: i.value
  }
};
export const validatePesel = (pesel) => {
  const re = /^[0-9]{11}$/;
  return re.test(pesel);

}
export const parseNumber = (value) => {
  const sanitizedValue = value.replace(/[^\d., ]/g, '');
  if (sanitizedValue.startsWith('.') || sanitizedValue.startsWith(',')) {
    return;
  }
  const spaceRemovedValue = sanitizedValue.replace(/\s/g, '');
  let dotCount = 0;
  let commaCount = 0;

  const formattedValue = spaceRemovedValue.replace(/[,.\s]/g, (match) => {
    if (match === '.') {
      dotCount++;
      return dotCount === 1 && commaCount === 0 ? '.' : '';
    } else if (match === ',') {
      commaCount++;
      return commaCount === 1 && dotCount === 0 ? '.' : '';
    } else {
      return match;
    }
  });
  return formattedValue
};

export const validateOnlyLatin = (newValue) => {
  //const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ. -]+$/;

  const regex = /^[^\u0400-\u04FF]+$/;
  return regex.test(newValue);
}

export const validatePostIndex = (newValue) => {
  var regex = /^(?:\d+-)*\d+$/;
  return regex.test(newValue);
}

export const validateEmail = (newValue) => {
  var regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regex.test(newValue);
}

export const validatePhone = (newValue) => {
  var regex = /^[+]?[./0-9]*[(]?[0-9]{1,4}[)]?[-./0-9]*$/;
  return regex.test(newValue);
}

export const validateNoneCyrillic = (newValue) => {
  var regex = /^[^\u0400-\u04FF]+$/;
  return regex.test(newValue);
}

export const validateNIP = (newValue) => {
  const regex = /^[^\s]*$/;
  return regex.test(newValue);
}



export const vatData = ['23%', '8%', '5%', '0%', 'zw.', 'np.', 'np.EU',];

export const explanationType = [ 'multisport','abonament','zw','oswiadczenie','sprzedaz','przychod'];

export const explanationTypeMap = [
  {
    value: 'multisport',
    label: 'multisport'
  },
  {
    value: 'abonament',
    label: 'abonament'
  },
  {
    value: 'zw',
    label: 'zw'
  },
  {
    value: 'oswiadczenie',
    label: 'oswiadczenie'
  },
  {
    value: 'sprzedaz',
    label: 'sprzedaz towary'
  },
  {
    value: 'przychod',
    label: 'przychod'
  }
];
export const GTUData = ['-', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];

export const UNIT_VARIANTS = ['szt.', 'godz.', 'op.', 'kg', 'm2', 'km', 'mb'];

export const laguagesForPdfDownload = (t) => [
  {
    id: 'pl',
    value: t('constants:pl')
  },
  {
    id: 'en',
    value: t('constants:en')
  },
  {
    id: 'de',
    value: t('constants:de')
  },
  {
    id: 'uk',
    value: t('constants:uk')
  }


];
export const laguagesForPdfDownloadOption = (t) => [
  {
    id: null,
    value: '-'
  },
  {
    id: 'pl',
    value: t('constants:pl')
  },
  {
    id: 'en',
    value: t('constants:en')
  },
  {
    id: 'de',
    value: t('constants:de')
  },
  {
    id: 'uk',
    value: t('constants:uk')
  }

]

const setAndValidate = (newValue, validateRegexpType, valueSetter, alertSetter, alertText,) => {      //name
  const isValid = validateRegexpType(newValue)
  if (!isValid && newValue.length !== 0) {
    alertSetter(alertText)
  }
  else {
    alertSetter('')
  }
  valueSetter(newValue)
};

export const REDIRECT_DELAY = 1000;
export const COMPANY_TYPE_COMPANY = 'COMPANY';
export const COMPANY_TYPE_INDIVIDUAL = 'INDIVIDUAL';
export const COMPANY_TYPE = (t) => [
  {
    id: COMPANY_TYPE_COMPANY,
    value: t('company_type_company')
  },
  {
    id: COMPANY_TYPE_INDIVIDUAL,
    value: t('company_type_individual')
  },
];

export const COUNTRY_TYPE = (t) => [
  {
    id: 'PL',
    value: t('constants:pl_country')
  },
  {
    id: 'EU',
    value: t('constants:eu')
  },
  {
    id: 'OTHER',
    value: t('constants:other_origin')
  },
]

export const COUNTRY_TYPE_MAP = (t) => {
  return {
    OTHER: t('constants:other_origin'),
    EU: t('constants:eu'),
    PL: t('constants:pl_country')
  }
}

export const SUPPORTED_CURRENCIES = [
  {
    value: 'PLN',
    label: 'PLN',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  }
]

export const CLIENT_STATES_ERRORS = (t) => {
  return {
    name: t("name"),
    lastName: t("last_name"),
    phone: t("phone"),
    passport: t("clients:create_client_placeholder_passport"),
    dateOfBirth: t("clients:create_client_placeholder_date_of_birth"),
    sitizenship: t("clients:create_client_placeholder_sitizen"),
    representativeCompany: t("clients:representative_company"),
    address: t("address"),
    postIndex: t("post_index"),
    city: t("city"),
    FOMAT_ERROR: t("format_data_invalid"),
    EMPTY_VALUE_ERROR: t("empty_data_invalid")
  }
}
export const COUNTERPARTIES_STATES_ERRORS = (t) => {
  return {
    name: t("counterparties:name"),
    lastName: t("last_name"),
    phone: t("phone"),
    passport: t("clients:create_client_placeholder_passport"),
    dateOfBirth: t("clients:create_client_placeholder_date_of_birth"),
    sitizenship: t("clients:create_client_placeholder_sitizen"),
    representativeCompany: t("clients:representative_company"),
    address: t("address"),
    postIndex: t("post_index"),
    city: t("city"),
    type: t("type"),
    origin: t("counterparties:origin"),
    country: t("country"),
    FOMAT_ERROR: t("format_data_invalid"),
    EMPTY_VALUE_ERROR: t("empty_data_invalid")
  }
}

export const TRANSACTION_TYPE_LOCAL = 'LOCAL';
export const TRANSACTION_TYPE_ABROAD = 'ABROAD';
export const TRANSACTION_TYPE = (t) => {

  return [
    {
      id: TRANSACTION_TYPE_LOCAL,
      value: t('constants:transaction_local')
    },
    {
      id: TRANSACTION_TYPE_ABROAD,
      value: t('constants:transaction_abroad')
    }
  ];
}

export const TRANSACTION_TYPE_MAP = (t) => {
  return {
    [TRANSACTION_TYPE_LOCAL]: t('constants:transaction_local'),
    [TRANSACTION_TYPE_ABROAD]: t('constants:transaction_abroad')
  }
}

export const PAYMENT_METHOD_BANK_ACCOUNT = 'BANK_ACCOUNT';
export const PAYMENT_METHOD = (t) => [
  {
    id: PAYMENT_METHOD_BANK_ACCOUNT,
    value: t('constants:payment_method_bank_account')
  }
];

export const PAYMENT_METHOD_MAP = (t) => {
  return {
    [PAYMENT_METHOD_BANK_ACCOUNT]: t('constants:payment_method_bank_account')
  }
}

export const PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED';
export const PAYMENT_NOT_COMPLETED = 'PAYMENT_NOT_COMPLETED';
export const PAYMENT_STATUS = (t) => {
  return [
    {
      id: 'PAYMENT_SUCCEEDED',
      value: t('constants:payment_succeeded')
    },
    {
      id: 'PAYMENT_NOT_COMPLETED',
      value: t('constants:payment_not_completed')
    }
  ];
}

export const Pending = 'Pending';
export const Accepted = 'Accepted';
export const BILL_STATUS = (t) => {
  return [
    {
      value: 'Accepted',
      label: t('constants:invoice_approved')
    },
    {
      value: 'Pending',
      label: t('constants:invoice_unapproved')
    }
  ];
}

export const PAYMENT_STATUS_MAP = (t) => {
  return {
    [PAYMENT_SUCCEEDED]: t('constants:payment_succeeded'),
    [PAYMENT_NOT_COMPLETED]: t('constants:payment_not_completed')
  }
}
export const STATUS = (t) => {
  return [
    {
      id: 'NOT_APPROVED',
      value: t('constants:invoice_unapproved')
    },
    {
      id: 'APPROVED',
      value: t('constants:invoice_approved')
    }
  ];
}

export const INVOICE_TYPES = {
  PREPAID_INVOICE: "PREPAID_INVOICE",
  PROFORMA: "PROFORMA",
  SALES_INVOICE: "SALES_INVOICE",
  FAKTURA_KONCOWA: "FAKTURA_KONCOWA",
}

export const RECORD_INVOICE_TYPE = {
  [INVOICE_TYPES.PROFORMA]: (t) => t('constants:invoice_proforma'),
  [INVOICE_TYPES.PREPAID_INVOICE]: (t) => t('constants:invoice_prepaid'),
  [INVOICE_TYPES.SALES_INVOICE]: (t) => t('constants:invoice_sales'),
  [INVOICE_TYPES.FAKTURA_KONCOWA]: (t) => t('constants:invoice_final'),
}

export const CONTRACT_TYPE = {
  UMOWA_O_DZIELO: 'UMOWA_O_DZIELO',
  UMOWA_ZLECENIE: 'UMOWA_ZLECENIE'
}

export const DECLARATION_LANGUAGE = {
  EN: 'EN',
  RU: 'RU'
}

export const DECLARATION_LANGUAGES = (t) => {
  return [
    {
      value: DECLARATION_LANGUAGE.EN,
      label: 'English'
    },
    {
      value: DECLARATION_LANGUAGE.RU,
      label: 'Russian'
    }
  ]
}

export const CONTRACT_TAX_DEDUCTIBLE = {
  ZERO_PERCENT: 'ZERO_PERCENT',
  TWENTY_PERCENT: 'TWENTY_PERCENT',
  FIFTY_PERCENT: 'FIFTY_PERCENT'
}

export const ContractPayType = {
  OneTime: 'OneTime',
  Monthly: 'Monthly',
  Hourly: 'Hourly'
}
export const CONTRACT_PAY_TYPES = (t) => {
  return [
    {
      value: ContractPayType.OneTime,
      label: t('contracts:one_time_payment')
    },
    {
      value: ContractPayType.Monthly,
      label: t('contracts:monthly_payment')
    },
    {
      value: ContractPayType.Hourly,
      label: t('contracts:hourly_payment')
    }
  ]
}
export const CONTRACT_TAX_DEDUCTIBLES = (t) => {
  return [
    {
      value: CONTRACT_TAX_DEDUCTIBLE.ZERO_PERCENT,
      label: '0%'
    },
    {
      value: CONTRACT_TAX_DEDUCTIBLE.TWENTY_PERCENT,
      label: '20%'
    },
    {
      value: CONTRACT_TAX_DEDUCTIBLE.FIFTY_PERCENT,
      label: '50%'
    }
  ]
}
export const CONTRACT_PIT = {
  ZERO_PERCENT: 'ZERO_PERCENT',
  TWELVE_PERCENT: 'TWELVE_PERCENT',
  THIRTY_TWO_PERCENT: 'THIRTY_TWO_PERCENT'
}
export const CONTRACT_STATE = {
  Pending: 'Pending',
  Accepted: 'Accepted',
  Ended: 'Ended'
}

const MIGRANT_EXPERT = 'MIGRANT_EXPERT';
const BUSINESS_EXPERT = 'BUSINESS_EXPERT';
const WEEXPERT = 'WEEXPERT';

export const CONTRACT_STATES = (t) => {
  return [
    {
      value: CONTRACT_STATE.Pending,
      label: t("contracts:pending")
    },
    {
      value: CONTRACT_STATE.Accepted,
      label: t("contracts:accepted")
    },
    {
      value: CONTRACT_STATE.Ended,
      label: t("contracts:ended")
    }
  ]
}
export const ANNEX_STATES = (t) => {
  return [
    {
      value: CONTRACT_STATE.Pending,
      label: t("contracts:pending")
    },
    {
      value: CONTRACT_STATE.Accepted,
      label: t("contracts:accepted")
    }
  ]
}
export const Signatories = {
  [MIGRANT_EXPERT]: [
    {
      value: 'Yehor Yehorov - Prezes Zarządu',
      label: 'Yehor Yehorov - Prezes Zarządu'
    },
    {
      value: 'Roman Bezzubchenko - Pełnomocnik',
      label: 'Roman Bezzubchenko - Pełnomocnik',
    },
    {
      value: 'Daria Kaliuzhna - Pełnomocnik',
      label: 'Daria Kaliuzhna - Pełnomocnik',
    }
  ],
  [BUSINESS_EXPERT]: [
    {
      value: 'Daria Kaliuzhna - Pełnomocnik',
      label: 'Daria Kaliuzhna - Pełnomocnik',
    },
    {
      value: 'Roman Bezzubchenko - Prezes Zarządu',
      label: 'Roman Bezzubchenko - Prezes Zarządu',
    }
  ],
  [WEEXPERT]: [
    {
      value: 'Daria Kaliuzhna - Pełnomocnik',
      label: 'Daria Kaliuzhna - Pełnomocnik',
    },
    {
      value: 'Roman Bezzubchenko - Prezes Zarządu',
      label: 'Roman Bezzubchenko - Prezes Zarządu',
    }
  ]
}

export const CONTRACT_PITS = (t) => {
  return [
    {
      value: CONTRACT_PIT.ZERO_PERCENT,
      label: '0%'
    },
    {
      value: CONTRACT_PIT.TWELVE_PERCENT,
      label: '12%'
    },
    {
      value: CONTRACT_PIT.THIRTY_TWO_PERCENT,
      label: '32%'
    }
  ]
}

export const CONTRACT_TYPES = (t) => {
  return [
    {
      value: CONTRACT_TYPE.UMOWA_O_DZIELO,
      label: 'Umowa o dzielo'
    },
    {
      value: CONTRACT_TYPE.UMOWA_ZLECENIE,
      label: 'Umowa zlecenie'
    },
  ]
}

export const RECORD_CONTRACT_TYPE = {
  [CONTRACT_TYPE.UMOWA_O_DZIELO]: (t) => t('contracts:contract_for_specific_work'),
  [CONTRACT_TYPE.UMOWA_ZLECENIE]: (t) => t("contracts:contract_of_mandate")
}



export const INVOICE_TYPE = (t) => {
  return [
    {
      id: INVOICE_TYPES.PROFORMA,
      value: t('constants:invoice_proforma')
    },
    {
      id: INVOICE_TYPES.SALES_INVOICE,
      value: t('constants:invoice_sales')
    },
    {
      id: INVOICE_TYPES.PREPAID_INVOICE,
      value: t('constants:invoice_prepaid')
    },
    {
      id: INVOICE_TYPES.FAKTURA_KONCOWA,
      value: t('constants:invoice_final')
    }
  ];
}

export const INVOICE_TYPE_FOR_CLIENT = (t) => {
  return [
    {
      id: INVOICE_TYPES.PROFORMA,
      value: t('constants:invoice_proforma')
    }
  ]
}

export const SKILLS_TYPES = {
  WEB_DEVELOPMENT: 'WEB_DEVELOPMENT',
  FRONTEND_DEVELOPMENT: "FRONTEND_DEVELOPMENT",
  BACKEND_DEVELOPMENT: "BACKEND_DEVELOPMENT",
  FULL_STACK_DEVELOPMENT: "FULL_STACK_DEVELOPMENT",
  MOBILE_APP_DEVELOPMENT: "MOBILE_APP_DEVELOPMENT",
  AR_VR: "AR_VR",
  _3D: "_3D",
  DEV_OPS: "DEV_OPS",
  SOLUTION_ARCHITECT: "SOLUTION_ARCHITECT",
  BIG_DATA: "BIG_DATA",
  UX_UI_DESIGN: "UX_UI_DESIGN",
  GRAPHIC_DESIGN: "GRAPHIC_DESIGN",
  VIDEO_EDITING: "VIDEO_EDITING",
  CONTENT_WRITING: "CONTENT_WRITING",
  COPYWRITING: "COPYWRITING",
  SEO: "SEO",
  SOCIAL_MEDIA_MARKETING: "SOCIAL_MEDIA_MARKETING",
  EMAIL_MARKETING: "EMAIL_MARKETING",
  DATA_ANALYSIS: "DATA_ANALYSIS",
  MACHINE_LEARNING: "MACHINE_LEARNING",
  PROJECT_MANAGEMENT: "PROJECT_MANAGEMENT",
  AGILE_METHODOLOGIES: "AGILE_METHODOLOGIES",
  CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
  SALES_MARKETING: "SALES_MARKETING",
  PRODUCT_MANAGEMENT: "PRODUCT_MANAGEMENT"
}

export const SKILLS_TYPE = () => {
  return [
    {
      value: SKILLS_TYPES.WEB_DEVELOPMENT,
      label: 'Web Development'
    },
    {
      value: SKILLS_TYPES.FRONTEND_DEVELOPMENT,
      label: 'Front-end Development'
    },
    {
      value: SKILLS_TYPES.BACKEND_DEVELOPMENT,
      label: 'Back-end Development'
    },
    {
      value: SKILLS_TYPES.FULL_STACK_DEVELOPMENT,
      label: 'Full Stack Delevopment'
    },
    {
      value: SKILLS_TYPES.MOBILE_APP_DEVELOPMENT,
      label: 'Mobile App Development(IOS/Android)'
    },
    {
      value: SKILLS_TYPES.AR_VR,
      label: 'AR/VR'
    },
    {
      value: SKILLS_TYPES._3D,
      label: '3D'
    },
    {
      value: SKILLS_TYPES.DEV_OPS,
      label: 'DevOps'
    },
    {
      value: SKILLS_TYPES.SOLUTION_ARCHITECT,
      label: 'Solution Architect'
    },
    {
      value: SKILLS_TYPES.BIG_DATA,
      label: 'BigData'
    },
    {
      value: SKILLS_TYPES.UX_UI_DESIGN,
      label: 'UX/UI Design'
    },
    {
      value: SKILLS_TYPES.GRAPHIC_DESIGN,
      label: 'Graphic Design'
    },
    {
      value: SKILLS_TYPES.VIDEO_EDITING,
      label: 'Video Editing'
    },
    {
      value: SKILLS_TYPES.CONTENT_WRITING,
      label: 'Content Writing'
    },
    {
      value: SKILLS_TYPES.COPYWRITING,
      label: 'Copywriting'
    },
    {
      value: SKILLS_TYPES.SEO,
      label: 'SEO(Search Engine Optimization)'
    },
    {
      value: SKILLS_TYPES.SOCIAL_MEDIA_MARKETING,
      label: 'Social Media Marketing'
    },
    {
      value: SKILLS_TYPES.EMAIL_MARKETING,
      label: 'Email Marketing'
    },
    {
      value: SKILLS_TYPES.DATA_ANALYSIS,
      label: 'Data Analysis'
    },
    {
      value: SKILLS_TYPES.MACHINE_LEARNING,
      label: 'Machine Learning'
    },
    {
      value: SKILLS_TYPES.PROJECT_MANAGEMENT,
      label: 'Project Management'
    },
    {
      value: SKILLS_TYPES.AGILE_METHODOLOGIES,
      label: 'Agile Methodologies'
    },
    {
      value: SKILLS_TYPES.CUSTOMER_SUPPORT,
      label: 'Customer Support'
    },
    {
      value: SKILLS_TYPES.SALES_MARKETING,
      label: 'Sales/Marketing'
    },
    {
      value: SKILLS_TYPES.PRODUCT_MANAGEMENT,
      label: 'Product Management'
    }
  ];
}

export const TARGET_KEYS = ["WEB_DEVELOPMENT", "FRONTEND_DEVELOPMENT", "BACKEND_DEVELOPMENT", "FULL_STACK_DEVELOPMENT", "MOBILE_APP_DEVELOPMENT", "DATA_ANALYSIS", "MACHINE_LEARNING", "DEV_OPS", "SOLUTION_ARCHITECT", "BIG_DATA", "AR_VR", "_3D"];

export const TOOLS_AND_TECHNOLOGIES_TYPES = {
  WORD_PRESS: "WORD_PRESS",
  SHOPIFY: "SHOPIFY",
  ADOBE_CREATIVE_SUITE: "ADOBE_CREATIVE_SUITE",
  FIGMA: "FIGMA",
  SKETCH: "SKETCH",
  SEO_TOOLS: "SEO_TOOLS",
  GIT: "GIT",
  DOCKER: "DOCKER",
  AWS: "AWS",
  GOOGLE_CLOUD: "GOOGLE_CLOUD",
  MICROSOFT_AZURE: "MICROSOFT_AZURE",
  CI_CD: "CI_CD",
  JENKINS: "JENKINS",
  KUBERNETIS: "KUBERNETIS",
  FIREBASE: "FIREBASE",
  WEB_FLOW: "WEB_FLOW"
}

export const TOOLS_AND_TECHNOLOGY_TYPE = () => {
  return [
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.WORD_PRESS,
      label: "WordPress"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.SHOPIFY,
      label: "Shopify"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.ADOBE_CREATIVE_SUITE,
      label: "Adobe Creative Suite"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.FIGMA,
      label: "Figma"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.SKETCH,
      label: "Sketch"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.SEO_TOOLS,
      label: "SEO Tools (e.g., SEMursh, Moz)"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.GIT,
      label: "Git"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.DOCKER,
      label: "Docker"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.AWS,
      label: "AWS"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.GOOGLE_CLOUD,
      label: "Google Cloud"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.MICROSOFT_AZURE,
      label: "Microsoft Azure"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.CI_CD,
      label: "CI/CD"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.JENKINS,
      label: "Jenkins"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.KUBERNETIS,
      label: "Kubernetes"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.FIREBASE,
      label: "Firebase"
    },
    {
      value: TOOLS_AND_TECHNOLOGIES_TYPES.WEB_FLOW,
      label: "WebFlow"
    }
  ]
}

export const PROGRAMMING_LANGUAGES_TYPES = {
  JAVASCRIPT: "JAVASCRIPT",
  PYTHON: "PYTHON",
  JAVA: "JAVA",
  RUBY: "RUBY",
  PHP: "PHP",
  C_SHARP: "C_SHARP",
  SWIFT: "SWIFT",
  KOTLIN: "KOTLIN",
  TYPESCRIPT: "TYPESCRIPT",
  HTML_CSS: "HTML_CSS",
  SQL: "SQL",
  C_PLUS_PLUS: "C_PLUS_PLUS",
  GO: "GO",
  RUST: "RUST",
  DART: "DART",
  SHELL_SCRIPTING: "SHELL_SCRIPTING"
}

export const PROGRAMMING_LANGUAGE_TYPE = () => {
  return [
    {
      value: PROGRAMMING_LANGUAGES_TYPES.JAVASCRIPT,
      label: "JavsScript"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.PYTHON,
      label: "Python"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.JAVA,
      label: "Java"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.RUBY,
      label: "Ruby"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.PHP,
      label: "PHP"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.C_SHARP,
      label: "C#"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.SWIFT,
      label: "Swift"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.KOTLIN,
      label: "Kotlin"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.TYPESCRIPT,
      label: "TypeScript"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.HTML_CSS,
      label: "HTML/CSS"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.SQL,
      label: "SQL"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.C_PLUS_PLUS,
      label: "C++"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.GO,
      label: "Go"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.RUST,
      label: "Rust"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.DART,
      label: "Dart"
    },
    {
      value: PROGRAMMING_LANGUAGES_TYPES.SHELL_SCRIPTING,
      label: "Shell Scripting"
    },
  ]
};

export const FRAMEWORKS_TYPES = {
  REACT_JS: "REACT_JS",
  ANGULAR: "ANGULAR",
  VUE_JS: "VUE_JS",
  DJANGO: "DJANGO",
  FLASK: "FLASK",
  RUBY_ON_RAILS: "RUBY_ON_RAILS",
  LARAVEL: "LARAVEL",
  SPRING_BOOT: "SPRING_BOOT",
  EXPRESS_JS: "EXPRESS_JS",
  WEB_GL: "WEB_GL",
  ASP_NET: "ASP_NET",
  FLUTTER: "FLUTTER",
  REACT_NATIVE: "REACT_NATIVE",
  XAMARIN: "XAMARIN",
  TENSOR_FLOW: "TENSOR_FLOW",
  PY_TORCH: "PY_TORCH",
  BOOTSTRAP: "BOOTSTRAP",
  TAILWIND_CSS: "TAILWIND_CSS",
  MATERIALIZE: "MATERIALIZE",

}

export const FRAMEWORK_TYPE = () => {
  return [
    {
      value: FRAMEWORKS_TYPES.REACT_JS,
      label: "React.js"
    },
    {
      value: FRAMEWORKS_TYPES.ANGULAR,
      label: "Angular"
    },
    {
      value: FRAMEWORKS_TYPES.VUE_JS,
      label: "Vue.js"
    },
    {
      value: FRAMEWORKS_TYPES.DJANGO,
      label: "Django"
    },
    {
      value: FRAMEWORKS_TYPES.FLASK,
      label: "Flask"
    },
    {
      value: FRAMEWORKS_TYPES.RUBY_ON_RAILS,
      label: "Ruby on Rails"
    },
    {
      value: FRAMEWORKS_TYPES.LARAVEL,
      label: "Laravel"
    },
    {
      value: FRAMEWORKS_TYPES.SPRING_BOOT,
      label: "Spring Boot"
    },
    {
      value: FRAMEWORKS_TYPES.EXPRESS_JS,
      label: "Expres.js"
    },
    {
      value: FRAMEWORKS_TYPES.WEB_GL,
      label: "WebGL"
    },
    {
      value: FRAMEWORKS_TYPES.ASP_NET,
      label: "ASP.NET"
    },
    {
      value: FRAMEWORKS_TYPES.FLUTTER,
      label: "Flutter"
    },
    {
      value: FRAMEWORKS_TYPES.REACT_NATIVE,
      label: "React Native"
    },
    {
      value: FRAMEWORKS_TYPES.XAMARIN,
      label: "Xamarin"
    },
    {
      value: FRAMEWORKS_TYPES.TENSOR_FLOW,
      label: "TensorFlow"
    },
    {
      value: FRAMEWORKS_TYPES.PY_TORCH,
      label: "PyTorch"
    },
    {
      value: FRAMEWORKS_TYPES.BOOTSTRAP,
      label: "Bootstrap"
    },
    {
      value: FRAMEWORKS_TYPES.TAILWIND_CSS,
      label: "Tailwind CSS"
    },
    {
      value: FRAMEWORKS_TYPES.MATERIALIZE,
      label: "Materialise"
    }
  ]
}

export const EDUCATIONS_TYPES = {
  HIGH_SCHOOL: "HIGH_SCHOOL",
  ASSOCIATES_DEGREE: "ASSOCIATES_DEGREE",
  BACHELORS_DEGREE: "BACHELORS_DEGREE",
  MASTERS_DEGREE: "MASTERS_DEGREE",
  DOCTORATE: "DOCTORATE",

}

export const EDUCATION_TYPE = () => {
  return [
    {
      id: EDUCATIONS_TYPES.HIGH_SCHOOL,
      value: "High School Diploma"
    },
    {
      id: EDUCATIONS_TYPES.ASSOCIATES_DEGREE,
      value: "Associate's degree"
    },
    {
      id: EDUCATIONS_TYPES.BACHELORS_DEGREE,
      value: "Bachelor's Degree"
    },
    {
      id: EDUCATIONS_TYPES.MASTERS_DEGREE,
      value: "Master's Degree"
    },
    {
      id: EDUCATIONS_TYPES.DOCTORATE,
      value: "Doctorate"
    },
  ]
}

export const STUDY_FIELDS = {
  COMPUTER_SCIENCE: "COMPUTER_SCIENCE",
  INFORMATION_TECHNOLOGY: "INFORMATION_TECHNOLOGY",
  BUSINESS_ADMINISTRATION: "BUSINESS_ADMINISTRATION",
  MARKETING: "MARKETING",
  GRAPHIC_DESIGN: "GRAPHIC_DESIGN",
  ENGINEERING: "ENGINEERING",
  FINANCE: "FINANCE",
  PSYCHOLOGY: "PSYCHOLOGY",
  COMMUNICATION: "COMMUNICATION",
  BIOLOGY: "BIOLOGY",
  CHEMISTRY: "CHEMISTRY",
  PHYSICS: "PHYSICS",
  SOCIOLOGY: "SOCIOLOGY",
  POLITICAL_SCIENCE: "POLITICAL_SCIENCE",
  ECONOMICS: "ECONOMICS",
  MATHEMATICS: "MATHEMATICS",
  EDUCATION: "EDUCATION",
  HEALTH_SCIENCES: "HEALTH_SCIENCES",
  FINE_ARTS: "FINE_ARTS",
  LINGUISTICS: "LINGUISTICS",
  PHILOSOPHY: "PHILOSOPHY",
  ENVIRONMENTAL_SCIENCE: "ENVIRONMENTAL_SCIENCE"
}

export const OPTIONS_STUDY_FIELD = () => {
  return [
    {
      id: STUDY_FIELDS.COMPUTER_SCIENCE,
      value: "Computer Science"
    },
    {
      id: STUDY_FIELDS.INFORMATION_TECHNOLOGY,
      value: "Information Technology"
    },
    {
      id: STUDY_FIELDS.BUSINESS_ADMINISTRATION,
      value: "Busines Administration"
    },
    {
      id: STUDY_FIELDS.MARKETING,
      value: "Marketing"
    },
    {
      id: STUDY_FIELDS.GRAPHIC_DESIGN,
      value: "Graphic Design"
    },
    {
      id: STUDY_FIELDS.ENGINEERING,
      value: "Engineering"
    },
    {
      id: STUDY_FIELDS.FINANCE,
      value: "Finance"
    },
    {
      id: STUDY_FIELDS.PSYCHOLOGY,
      value: "Psychology"
    },
    {
      id: STUDY_FIELDS.COMMUNICATION,
      value: "Communication"
    },
    {
      id: STUDY_FIELDS.BIOLOGY,
      value: "Biology"
    },
    {
      id: STUDY_FIELDS.CHEMISTRY,
      value: "Chemistry"
    },
    {
      id: STUDY_FIELDS.PHYSICS,
      value: "Physics"
    },
    {
      id: STUDY_FIELDS.SOCIOLOGY,
      value: "Sociology"
    },
    {
      id: STUDY_FIELDS.POLITICAL_SCIENCE,
      value: "Political Science"
    },
    {
      id: STUDY_FIELDS.ECONOMICS,
      value: "Economics"
    },
    {
      id: STUDY_FIELDS.MATHEMATICS,
      value: "Mathematics"
    },
    {
      id: STUDY_FIELDS.EDUCATION,
      value: "Education"
    },
    {
      id: STUDY_FIELDS.HEALTH_SCIENCES,
      value: "Health Sciences"
    },
    {
      id: STUDY_FIELDS.FINE_ARTS,
      value: "Fine Arts"
    },
    {
      id: STUDY_FIELDS.LINGUISTICS,
      value: "Linguistics"
    },
    {
      id: STUDY_FIELDS.PHILOSOPHY,
      value: "Philosophy"
    },
    {
      id: STUDY_FIELDS.ENVIRONMENTAL_SCIENCE,
      value: "Environmental Science"
    },
  ]
}

export const LANGUAGES_TYPES = {
  ENGLISH: "ENGLISH",
  SPANISH: "SPANISH",
  FRENCH: "FRENCH",
  GERMAN: "GERMAN",
  CHINESE: "CHINESE",
  JAPANESE: "JAPANESE",
  KOREAN: "KOREAN",
  RUSSIAN: "RUSSIAN",
  UKRAINIAN: "UKRAINIAN",
  PORTUGUESE: "PORTUGUESE",
  ARABIC: "ARABIC",
  HINDI: "HINDI",
  URDU: "URDU",
  POLISH: "POLISH",
}

export const OPTIONS_LANGUAGE = () => {
  return [
    {
      id: LANGUAGES_TYPES.ENGLISH,
      value: "English"
    },
    {
      id: LANGUAGES_TYPES.SPANISH,
      value: "Spanish"
    },
    {
      id: LANGUAGES_TYPES.POLISH,
      value: "Polish"
    },
    {
      id: LANGUAGES_TYPES.FRENCH,
      value: "French"
    },
    {
      id: LANGUAGES_TYPES.GERMAN,
      value: "German"
    },
    {
      id: LANGUAGES_TYPES.CHINESE,
      value: "Chinese (Mandarin/Cantonese)"
    },
    {
      id: LANGUAGES_TYPES.JAPANESE,
      value: "Japanese"
    },
    {
      id: LANGUAGES_TYPES.KOREAN,
      value: "Korean"
    },
    {
      id: LANGUAGES_TYPES.RUSSIAN,
      value: "Russian"
    },
    {
      id: LANGUAGES_TYPES.UKRAINIAN,
      value: "Ukrainian"
    },
    {
      id: LANGUAGES_TYPES.PORTUGUESE,
      value: "Portuguese"
    },
    {
      id: LANGUAGES_TYPES.ARABIC,
      value: "Arabic"
    },
    {
      id: LANGUAGES_TYPES.HINDI,
      value: "Hindi"
    },
    {
      id: LANGUAGES_TYPES.URDU,
      value: "Urdu"
    },
  ]
}


export const LANGUAGE_LEVELS = {
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  FLUENT: 'FLUENT'
}

export const OPTIONS_LANGUAGE_LEVEL = () => {
  return [
    {
      id: LANGUAGE_LEVELS.BEGINNER,
      value: "Beginner"
    },
    {
      id: LANGUAGE_LEVELS.INTERMEDIATE,
      value: "Intermediate"
    },
    {
      id: LANGUAGE_LEVELS.ADVANCED,
      value: "Advanced"
    },
    {
      id: LANGUAGE_LEVELS.FLUENT,
      value: "Fluent"
    },
  ]
};

export const EXISTING_ABILITY_ID = [...PROGRAMMING_LANGUAGE_TYPE().map(e => e.value),
...SKILLS_TYPE().map(e => e.value),
...FRAMEWORK_TYPE().map(e => e.value),
...TOOLS_AND_TECHNOLOGY_TYPE().map(e => e.value)
];
export const HOURS_OF_WORK_TYPES = {
  LESS_THAN_10: "LESS_THAN_10",
  _10_20: "_10_20",
  _20_30: "_20_30",
  MORE_THAN_30: "MORE_THAN_30"
}

export const HOUR_OF_WORK_TYPE = () => {
  return [
    {
      id: HOURS_OF_WORK_TYPES.LESS_THAN_10,
      value: "Less than 10 hours"
    },
    {
      id: HOURS_OF_WORK_TYPES._10_20,
      value: "10-20 hours"
    },
    {
      id: HOURS_OF_WORK_TYPES._20_30,
      value: "20-30 hours"
    },
    {
      id: HOURS_OF_WORK_TYPES.MORE_THAN_30,
      value: "More than 30 hours"
    },
  ]
}

export const INVOICE_TYPE_FOR_PROFORMA = (t) => {
  return [
    {
      id: 'SALES_INVOICE',
      value: t('constants:invoice_sales')
    },
    {
      id: 'PREPAID_INVOICE',
      value: t('constants:invoice_prepaid')
    },
  ];
}
export const INVOICE_TYPE_FOR_PREPAID_INVOICE = (t) => {
  return [
    {
      id: 'FAKTURA_KONCOWA',
      value: t('constants:invoice_final')
    }
  ];
}

export const PAGINATION_SETTINGS = {
  clients: 50,
  invoices: 50,
  users: 50,
  counterparty: 50,
  bills: 50,
  contracts: 50,
  specifications: 50,
  reports: 50,
  transactions: 50
}

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100]

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  HEAD_OF_DEPARTMENT: "HEAD_OF_DEPARTMENT",
  ASSISTANT: "ASSISTANT",
  CLIENT: "CLIENT"
}

export const rolesForUsersCreate = (t) => [
  {
    value: 'SUPER_ADMIN',
    display: t('users:SUPER_ADMIN')
  },
  {
    value: 'ADMIN',
    display: t('users:ADMIN')
  },
  {
    value: 'HEAD_OF_DEPARTMENT',
    display: t('users:HEAD_OF_DEPARTMENT')
  },
  {
    value: 'ASSISTANT',
    display: t('users:ASSISTANT')
  }
];

export const AVAILABILITY_HOURS = (t) => [
  {
    value: 'LESS_THAN_10',
    label: t("constants:LESS_THAN_10")
  },
  {
    value: '10_20_HOURS',
    label: t("constants:10_20_HOURS")
  },
  {
    value: '20_30_HOURS',
    label: t("constants:20_30_HOURS")
  },
  {
    value: 'MORE_THAN_30',
    label: t("constants:MORE_THAN_30")
  }
]

export const ACTIVE_STATUS = (t) => {

  return [
    {
      id: true,
      value: t('constants:blocked')
    },
    {
      id: false,
      value: t('constants:unblocked')
    }
  ]
}

export const ALLOWED_ASSIGN_CLIENT = [ROLES.ADMIN, ROLES.HEAD_OF_DEPARTMENT, ROLES.SUPER_ADMIN];

export const ALLOWED_LINK_RESTORE = [ROLES.ADMIN, ROLES.HEAD_OF_DEPARTMENT, ROLES.SUPER_ADMIN, ROLES.ASSISTANT];
export const ALLOWED_BLOCK_CLIENT = [ROLES.ADMIN, ROLES.HEAD_OF_DEPARTMENT, ROLES.SUPER_ADMIN];
export const REPRESENTATIVE_COMPANY_LIST = [
  {
    id: MIGRANT_EXPERT,
    value: 'Migrant expert'
  },
  {
    id: BUSINESS_EXPERT,
    value: 'Biznes expert'
  },
  {
    id: WEEXPERT,
    value: 'Weexpert'
  }
]

export const COUNTRIES_LIST = [
  {
    id: "af",
    value: "Afganistan"
  },
  {
    id: "al",
    value: "Albania"
  },
  {
    id: "dz",
    value: "Algieria"
  },
  {
    id: "ad",
    value: "Andora"
  },
  {
    id: "ao",
    value: "Angola"
  },
  {
    id: "ag",
    value: "Antigua i Barbuda"
  },
  {
    id: "sa",
    value: "Arabia Saudyjska"
  },
  {
    id: "ar",
    value: "Argentyna"
  },
  {
    id: "am",
    value: "Armenia"
  },
  {
    id: "au",
    value: "Australia"
  },
  {
    id: "at",
    value: "Austria"
  },
  {
    id: "az",
    value: "Azerbejdżan"
  },
  {
    id: "bs",
    value: "Bahamy"
  },
  {
    id: "bh",
    value: "Bahrajn"
  },
  {
    id: "bd",
    value: "Bangladesz"
  },
  {
    id: "bb",
    value: "Barbados"
  },
  {
    id: "be",
    value: "Belgia"
  },
  {
    id: "bz",
    value: "Belize"
  },
  {
    id: "bj",
    value: "Benin"
  },
  {
    id: "bt",
    value: "Bhutan"
  },
  {
    id: "by",
    value: "Białoruś"
  },
  {
    id: "bo",
    value: "Boliwia"
  },
  {
    id: "ba",
    value: "Bośnia i Hercegowina"
  },
  {
    id: "bw",
    value: "Botswana"
  },
  {
    id: "br",
    value: "Brazylia"
  },
  {
    id: "bn",
    value: "Brunei"
  },
  {
    id: "bg",
    value: "Bułgaria"
  },
  {
    id: "bf",
    value: "Burkina Faso"
  },
  {
    id: "bi",
    value: "Burundi"
  },
  {
    id: "cl",
    value: "Chile"
  },
  {
    id: "cn",
    value: "Chiny"
  },
  {
    id: "hr",
    value: "Chorwacja"
  },
  {
    id: "cy",
    value: "Cypr"
  },
  {
    id: "td",
    value: "Czad"
  },
  {
    id: "me",
    value: "Czarnogóra"
  },
  {
    id: "cz",
    value: "Czechy"
  },
  {
    id: "dk",
    value: "Dania"
  },
  {
    id: "cd",
    value: "Demokratyczna Republika Konga"
  },
  {
    id: "dm",
    value: "Dominika"
  },
  {
    id: "do",
    value: "Dominikana"
  },
  {
    id: "dj",
    value: "Dżibuti"
  },
  {
    id: "eg",
    value: "Egipt"
  },
  {
    id: "ec",
    value: "Ekwador"
  },
  {
    id: "er",
    value: "Erytrea"
  },
  {
    id: "ee",
    value: "Estonia"
  },
  {
    id: "sz",
    value: "Eswatini"
  },
  {
    id: "et",
    value: "Etiopia"
  },
  {
    id: "fj",
    value: "Fidżi"
  },
  {
    id: "ph",
    value: "Filipiny"
  },
  {
    id: "fi",
    value: "Finlandia"
  },
  {
    id: "fr",
    value: "Francja"
  },
  {
    id: "ga",
    value: "Gabon"
  },
  {
    id: "gm",
    value: "Gambia"
  },
  {
    id: "gh",
    value: "Ghana"
  },
  {
    id: "gr",
    value: "Grecja"
  },
  {
    id: "gd",
    value: "Grenada"
  },
  {
    id: "ge",
    value: "Gruzja"
  },
  {
    id: "gy",
    value: "Gujana"
  },
  {
    id: "gt",
    value: "Gwatemala"
  },
  {
    id: "gw",
    value: "Gwinea Bissau"
  },
  {
    id: "gq",
    value: "Gwinea Równikowa"
  },
  {
    id: "gn",
    value: "Gwinea"
  },
  {
    id: "ht",
    value: "Haiti"
  },
  {
    id: "es",
    value: "Hiszpania"
  },
  {
    id: "nl",
    value: "Holandia"
  },
  {
    id: "hn",
    value: "Honduras"
  },
  {
    id: "in",
    value: "Indie"
  },
  {
    id: "id",
    value: "Indonezja"
  },
  {
    id: "iq",
    value: "Irak"
  },
  {
    id: "ir",
    value: "Iran"
  },
  {
    id: "ie",
    value: "Irlandia"
  },
  {
    id: "is",
    value: "Islandia"
  },
  {
    id: "il",
    value: "Izrael"
  },
  {
    id: "jm",
    value: "Jamajka"
  },
  {
    id: "jp",
    value: "Japonia"
  },
  {
    id: "ye",
    value: "Jemen"
  },
  {
    id: "jo",
    value: "Jordania"
  },
  {
    id: "kh",
    value: "Kambodża"
  },
  {
    id: "cm",
    value: "Kamerun"
  },
  {
    id: "ca",
    value: "Kanada"
  },
  {
    id: "qa",
    value: "Katar"
  },
  {
    id: "kz",
    value: "Kazachstan"
  },
  {
    id: "ke",
    value: "Kenia"
  },
  {
    id: "kg",
    value: "Kirgistan"
  },
  {
    id: "ki",
    value: "Kiribati"
  },
  {
    id: "co",
    value: "Kolumbia"
  },
  {
    id: "km",
    value: "Komory"
  },
  {
    id: "cg",
    value: "Kongo"
  },
  {
    id: "kr",
    value: "Korea Południowa"
  },
  {
    id: "kp",
    value: "Korea Północna"
  },
  {
    id: "cr",
    value: "Kostaryka"
  },
  {
    id: "cu",
    value: "Kuba"
  },
  {
    id: "kw",
    value: "Kuwejt"
  },
  {
    id: "la",
    value: "Laos"
  },
  {
    id: "ls",
    value: "Lesotho"
  },
  {
    id: "lb",
    value: "Liban"
  },
  {
    id: "lr",
    value: "Liberia"
  },
  {
    id: "ly",
    value: "Libia"
  },
  {
    id: "li",
    value: "Liechtenstein"
  },
  {
    id: "lt",
    value: "Litwa"
  },
  {
    id: "lu",
    value: "Luksemburg"
  },
  {
    id: "lv",
    value: "Łotwa"
  },
  {
    id: "mk",
    value: "Macedonia Północna"
  },
  {
    id: "mg",
    value: "Madagaskar"
  },
  {
    id: "mw",
    value: "Malawi"
  },
  {
    id: "mv",
    value: "Malediwy"
  },
  {
    id: "my",
    value: "Malezja"
  },
  {
    id: "ml",
    value: "Mali"
  },
  {
    id: "mt",
    value: "Malta"
  },
  {
    id: "ma",
    value: "Maroko"
  },
  {
    id: "mr",
    value: "Mauretania"
  },
  {
    id: "mu",
    value: "Mauritius"
  },
  {
    id: "mx",
    value: "Meksyk"
  },
  {
    id: "fm",
    value: "Mikronezja"
  },
  {
    id: "mm",
    value: "Mjanma"
  },
  {
    id: "md",
    value: "Mołdawia"
  },
  {
    id: "mc",
    value: "Monako"
  },
  {
    id: "mn",
    value: "Mongolia"
  },
  {
    id: "mz",
    value: "Mozambik"
  },
  {
    id: "na",
    value: "Namibia"
  },
  {
    id: "nr",
    value: "Nauru"
  },
  {
    id: "np",
    value: "Nepal"
  },
  {
    id: "de",
    value: "Niemcy"
  },
  {
    id: "ne",
    value: "Niger"
  },
  {
    id: "ng",
    value: "Nigeria"
  },
  {
    id: "ni",
    value: "Nikaragua"
  },
  {
    id: "no",
    value: "Norwegia"
  },
  {
    id: "nz",
    value: "Nowa Zelandia"
  },
  {
    id: "om",
    value: "Oman"
  },
  {
    id: "pk",
    value: "Pakistan"
  },
  {
    id: "pw",
    value: "Palau"
  },
  {
    id: "pa",
    value: "Panama"
  },
  {
    id: "pg",
    value: "Papua-Nowa Gwinea"
  },
  {
    id: "py",
    value: "Paragwaj"
  },
  {
    id: "pe",
    value: "Peru"
  },
  {
    id: "pl",
    value: "Polska"
  },
  {
    id: "pt",
    value: "Portugalia"
  },
  {
    id: "za",
    value: "Południowa Afryka"
  },
  {
    id: "cf",
    value: "Republika Środkowoafrykańska"
  },
  {
    id: "cv",
    value: "Republika Zielonego Przylądka"
  },
  {
    id: "ru",
    value: "Rosja"
  },
  {
    id: "ro",
    value: "Rumunia"
  },
  {
    id: "rw",
    value: "Rwanda"
  },
  {
    id: "kn",
    value: "Saint Kitts i Nevis"
  },
  {
    id: "lc",
    value: "Saint Lucia"
  },
  {
    id: "vc",
    value: "Saint Vincent i Grenadyny"
  },
  {
    id: "sv",
    value: "Salwador"
  },
  {
    id: "ws",
    value: "Samoa"
  },
  {
    id: "sm",
    value: "San Marino"
  },
  {
    id: "sn",
    value: "Senegal"
  },
  {
    id: "rs",
    value: "Serbia"
  },
  {
    id: "sc",
    value: "Seszele"
  },
  {
    id: "sl",
    value: "Sierra Leone"
  },
  {
    id: "sg",
    value: "Singapur"
  },
  {
    id: "sk",
    value: "Słowacja"
  },
  {
    id: "si",
    value: "Słowenia"
  },
  {
    id: "so",
    value: "Somalia"
  },
  {
    id: "lk",
    value: "Sri Lanka"
  },
  {
    id: "us",
    value: "Stany Zjednoczone"
  },
  {
    id: "sd",
    value: "Sudan"
  },
  {
    id: "ss",
    value: "Sudan Południowy"
  },
  {
    id: "sr",
    value: "Surinam"
  },
  {
    id: "sy",
    value: "Syria"
  },
  {
    id: "ch",
    value: "Szwajcaria"
  },
  {
    id: "se",
    value: "Szwecja"
  },
  {
    id: "tj",
    value: "Tadżykistan"
  },
  {
    id: "th",
    value: "Tajlandia"
  },
  {
    id: "tz",
    value: "Tanzania"
  },
  {
    id: "tl",
    value: "Timor Wschodni"
  },
  {
    id: "tg",
    value: "Togo"
  },
  {
    id: "to",
    value: "Tonga"
  },
  {
    id: "tt",
    value: "Trynidad i Tobago"
  },
  {
    id: "tn",
    value: "Tunezja"
  },
  {
    id: "tr",
    value: "Turcja"
  },
  {
    id: "tm",
    value: "Turkmenistan"
  },
  {
    id: "tv",
    value: "Tuvalu"
  },
  {
    id: "ug",
    value: "Uganda"
  },
  {
    id: "ua",
    value: "Ukraina"
  },
  {
    id: "uy",
    value: "Urugwaj"
  },
  {
    id: "uz",
    value: "Uzbekistan"
  },
  {
    id: "vu",
    value: "Vanuatu"
  },
  {
    id: "ve",
    value: "Wenezuela"
  },
  {
    id: "hu",
    value: "Węgry"
  },
  {
    id: "gb",
    value: "Wielka Brytania"
  },
  {
    id: "vn",
    value: "Wietnam"
  },
  {
    id: "it",
    value: "Włochy"
  },
  {
    id: "ci",
    value: "Wybrzeże Kości Słoniowej"
  },
  {
    id: "mh",
    value: "Wyspy Marshalla"
  },
  {
    id: "sb",
    value: "Wyspy Salomona"
  },
  {
    id: "st",
    value: "Wyspy Świętego Tomasza i Książęca"
  },
  {
    id: "zm",
    value: "Zambia"
  },
  {
    id: "zw",
    value: "Zimbabwe"
  },
  {
    id: "ae",
    value: "Zjednoczone Emiraty Arabskie"
  }
]

const COUNTRIES_MAP = {
  af: {
    pl: "Afganistan"
  },
  al: {
    pl: "Albania"
  },
  dz: {
    pl: "Algieria"
  },
  ad: {
    pl: "Andora"
  },
  ao: {
    pl: "Angola"
  },
  ag: {
    pl: "Antigua i Barbuda"
  },
  sa: {
    pl: "Arabia Saudyjska"
  },
  ar: {
    pl: "Argentyna"
  },
  am: {
    pl: "Armenia"
  },
  au: {
    pl: "Australia"
  },
  at: {
    pl: "Austria"
  },
  az: {
    pl: "Azerbejdżan"
  },
  bs: {
    pl: "Bahamy"
  },
  bh: {
    pl: "Bahrajn"
  },
  bd: {
    pl: "Bangladesz"
  },
  bb: {
    pl: "Barbados"
  },
  be: {
    pl: "Belgia"
  },
  bz: {
    pl: "Belize"
  },
  bj: {
    pl: "Benin"
  },
  bt: {
    pl: "Bhutan"
  },
  by: {
    pl: "Białoruś"
  },
  bo: {
    pl: "Boliwia"
  },
  ba: {
    pl: "Bośnia i Hercegowina"
  },
  bw: {
    pl: "Botswana"
  },
  br: {
    pl: "Brazylia"
  },
  bn: {
    pl: "Brunei"
  },
  bg: {
    pl: "Bułgaria"
  },
  bf: {
    pl: "Burkina Faso"
  },
  bi: {
    pl: "Burundi"
  },
  cl: {
    pl: "Chile"
  },
  cn: {
    pl: "Chiny"
  },
  hr: {
    pl: "Chorwacja"
  },
  cy: {
    pl: "Cypr"
  },
  td: {
    pl: "Czad"
  },
  me: {
    pl: "Czarnogóra"
  },
  cz: {
    pl: "Czechy"
  },
  dk: {
    pl: "Dania"
  },
  cd: {
    pl: "Demokratyczna Republika Konga"
  },
  dm: {
    pl: "Dominika"
  },
  do: {
    pl: "Dominikana"
  },
  dj: {
    pl: "Dżibuti"
  },
  eg: {
    pl: "Egipt"
  },
  ec: {
    pl: "Ekwador"
  },
  er: {
    pl: "Erytrea"
  },
  ee: {
    pl: "Estonia"
  },
  sz: {
    pl: "Eswatini"
  },
  et: {
    pl: "Etiopia"
  },
  fj: {
    pl: "Fidżi"
  },
  ph: {
    pl: "Filipiny"
  },
  fi: {
    pl: "Finlandia"
  },
  fr: {
    pl: "Francja"
  },
  ga: {
    pl: "Gabon"
  },
  gm: {
    pl: "Gambia"
  },
  gh: {
    pl: "Ghana"
  },
  gr: {
    pl: "Grecja"
  },
  gd: {
    pl: "Grenada"
  },
  ge: {
    pl: "Gruzja"
  },
  gy: {
    pl: "Gujana"
  },
  gt: {
    pl: "Gwatemala"
  },
  gw: {
    pl: "Gwinea Bissau"
  },
  gq: {
    pl: "Gwinea Równikowa"
  },
  gn: {
    pl: "Gwinea"
  },
  ht: {
    pl: "Haiti"
  },
  es: {
    pl: "Hiszpania"
  },
  nl: {
    pl: "Holandia"
  },
  hn: {
    pl: "Honduras"
  },
  in: {
    pl: "Indie"
  },
  id: {
    pl: "Indonezja"
  },
  iq: {
    pl: "Irak"
  },
  ir: {
    pl: "Iran"
  },
  ie: {
    pl: "Irlandia"
  },
  is: {
    pl: "Islandia"
  },
  il: {
    pl: "Izrael"
  },
  jm: {
    pl: "Jamajka"
  },
  jp: {
    pl: "Japonia"
  },
  ye: {
    pl: "Jemen"
  },
  jo: {
    pl: "Jordania"
  },
  kh: {
    pl: "Kambodża"
  },
  cm: {
    pl: "Kamerun"
  },
  ca: {
    pl: "Kanada"
  },
  qa: {
    pl: "Katar"
  },
  kz: {
    pl: "Kazachstan"
  },
  ke: {
    pl: "Kenia"
  },
  kg: {
    pl: "Kirgistan"
  },
  ki: {
    pl: "Kiribati"
  },
  co: {
    pl: "Kolumbia"
  },
  km: {
    pl: "Komory"
  },
  cg: {
    pl: "Kongo"
  },
  kr: {
    pl: "Korea Południowa"
  },
  kp: {
    pl: "Korea Północna"
  },
  cr: {
    pl: "Kostaryka"
  },
  cu: {
    pl: "Kuba"
  },
  kw: {
    pl: "Kuwejt"
  },
  la: {
    pl: "Laos"
  },
  ls: {
    pl: "Lesotho"
  },
  lb: {
    pl: "Liban"
  },
  lr: {
    pl: "Liberia"
  },
  ly: {
    pl: "Libia"
  },
  li: {
    pl: "Liechtenstein"
  },
  lt: {
    pl: "Litwa"
  },
  lu: {
    pl: "Luksemburg"
  },
  lv: {
    pl: "Łotwa"
  },
  mk: {
    pl: "Macedonia Północna"
  },
  mg: {
    pl: "Madagaskar"
  },
  mw: {
    pl: "Malawi"
  },
  mv: {
    pl: "Malediwy"
  },
  my: {
    pl: "Malezja"
  },
  ml: {
    pl: "Mali"
  },
  mt: {
    pl: "Malta"
  },
  ma: {
    pl: "Maroko"
  },
  mr: {
    pl: "Mauretania"
  },
  mu: {
    pl: "Mauritius"
  },
  mx: {
    pl: "Meksyk"
  },
  fm: {
    pl: "Mikronezja"
  },
  mm: {
    pl: "Mjanma"
  },
  md: {
    pl: "Mołdawia"
  },
  mc: {
    pl: "Monako"
  },
  mn: {
    pl: "Mongolia"
  },
  mz: {
    pl: "Mozambik"
  },
  na: {
    pl: "Namibia"
  },
  nr: {
    pl: "Nauru"
  },
  np: {
    pl: "Nepal"
  },
  de: {
    pl: "Niemcy"
  },
  ne: {
    pl: "Niger"
  },
  ng: {
    pl: "Nigeria"
  },
  ni: {
    pl: "Nikaragua"
  },
  no: {
    pl: "Norwegia"
  },
  nz: {
    pl: "Nowa Zelandia"
  },
  om: {
    pl: "Oman"
  },
  pk: {
    pl: "Pakistan"
  },
  pw: {
    pl: "Palau"
  },
  pa: {
    pl: "Panama"
  },
  pg: {
    pl: "Papua-Nowa Gwinea"
  },
  py: {
    pl: "Paragwaj"
  },
  pe: {
    pl: "Peru"
  },
  pl: {
    pl: "Polska"
  },
  pt: {
    pl: "Portugalia"
  },
  za: {
    pl: "Południowa Afryka"
  },
  cf: {
    pl: "Republika Środkowoafrykańska"
  },
  cv: {
    pl: "Republika Zielonego Przylądka"
  },
  ru: {
    pl: "Rosja"
  },
  ro: {
    pl: "Rumunia"
  },
  rw: {
    pl: "Rwanda"
  },
  kn: {
    pl: "Saint Kitts i Nevis"
  },
  lc: {
    pl: "Saint Lucia"
  },
  vc: {
    pl: "Saint Vincent i Grenadyny"
  },
  sv: {
    pl: "Salwador"
  },
  ws: {
    pl: "Samoa"
  },
  sm: {
    pl: "San Marino"
  },
  sn: {
    pl: "Senegal"
  },
  rs: {
    pl: "Serbia"
  },
  sc: {
    pl: "Seszele"
  },
  sl: {
    pl: "Sierra Leone"
  },
  sg: {
    pl: "Singapur"
  },
  sk: {
    pl: "Słowacja"
  },
  si: {
    pl: "Słowenia"
  },
  so: {
    pl: "Somalia"
  },
  lk: {
    pl: "Sri Lanka"
  },
  us: {
    pl: "Stany Zjednoczone"
  },
  sd: {
    pl: "Sudan"
  },
  ss: {
    pl: "Sudan Południowy"
  },
  sr: {
    pl: "Surinam"
  },
  sy: {
    pl: "Syria"
  },
  ch: {
    pl: "Szwajcaria"
  },
  se: {
    pl: "Szwecja"
  },
  tj: {
    pl: "Tadżykistan"
  },
  th: {
    pl: "Tajlandia"
  },
  tz: {
    pl: "Tanzania"
  },
  tl: {
    pl: "Timor Wschodni"
  },
  tg: {
    pl: "Togo"
  },
  to: {
    pl: "Tonga"
  },
  tt: {
    pl: "Trynidad i Tobago"
  },
  tn: {
    pl: "Tunezja"
  },
  tr: {
    pl: "Turcja"
  },
  tm: {
    pl: "Turkmenistan"
  },
  tv: {
    pl: "Tuvalu"
  },
  ug: {
    pl: "Uganda"
  },
  ua: {
    pl: "Ukraina"
  },
  uy: {
    pl: "Urugwaj"
  },
  uz: {
    pl: "Uzbekistan"
  },
  vu: {
    pl: "Vanuatu"
  },
  ve: {
    pl: "Wenezuela"
  },
  hu: {
    pl: "Węgry"
  },
  gb: {
    pl: "Wielka Brytania"
  },
  vn: {
    pl: "Wietnam"
  },
  it: {
    pl: "Włochy"
  },
  ci: {
    pl: "Wybrzeże Kości Słoniowej"
  },
  mh: {
    pl: "Wyspy Marshalla"
  },
  sb: {
    pl: "Wyspy Salomona"
  },
  st: {
    pl: "Wyspy Świętego Tomasza i Książęca"
  },
  zm: {
    pl: "Zambia"
  },
  zw: {
    pl: "Zimbabwe"
  },
  ae: {
    pl: "Zjednoczone Emiraty Arabskie"
  }
};

export const GET_COUNTRY_NAME_BY_CODE = (code) => {
  return COUNTRIES_MAP[code] ? COUNTRIES_MAP[code].pl ?? '' : ''
}
