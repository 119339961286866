import * as React from 'react';
import { useState } from 'react';
import { ROLES } from '../../constants';
import { useTranslation } from 'react-i18next';
import AppBarForLogin from '../app-bar-for-main-login-pages/AppBarForLogin';
import logo from '../../assets/img/barLogo.png';
import arrowLeft from '../../assets/img/arrow-left.png';
import { CubeIcon, FileTextIcon, PersonIcon, BackpackIcon, LaptopIcon, AvatarIcon, ReaderIcon, ClipboardIcon } from '@radix-ui/react-icons';
import { Link, useNavigate } from 'react-router-dom';
import VideoPopup from '../ui/dialogWithVideo';
import "../../i18.js";
import { useEffect } from 'react';
import { FileStackIcon, PalmtreeIcon } from 'lucide-react';
import AlertBanner from '../ui/alert-banner';

const SuperAdmin = (t) => {
  return [
    {
      nav: t("navigation:main"),
      link: '/',
      img: <CubeIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:invoices"),
      link: '/admin/invoices/sales',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:users"),
      link: '/admin/administration',
      img: <AvatarIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:clients"),
      link: '/admin/clients',
      img: <BackpackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:counterparties"),
      link: '/admin/contractors',
      img: <PersonIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:freelancers"),
      link: 'admin/freelancers',
      img: <LaptopIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:vacation"),
      link: 'admin/user/client-shared-pool',
      img: <PalmtreeIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:contracts"),
      link: 'contracts',
      img: <FileStackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:bills"),
      link: 'bills',
      img: <ReaderIcon className='w-5 h-5' />
    },
    {
      nav: t('navigation:specifications'),
      link: '/specifications',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t('reports:report_title'),
      link: '/reports',
      img: <ClipboardIcon className='w-5 h-5' />
    },
  ]
}

const HeadOfDepartmentOptions = (t) => {
  return [
    {
      nav: t("navigation:main"),
      link: '/',
      img: <CubeIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:invoices"),
      link: '/admin/invoices/sales',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:users"),
      link: '/admin/administration',
      img: <AvatarIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:clients"),
      link: '/admin/clients',
      img: <BackpackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:counterparties"),
      link: '/admin/contractors',
      img: <PersonIcon className='w-5 h-5' />
    },
    {
      nav: t("freelancers"),
      link: 'admin/freelancers',
      img: <LaptopIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:contracts"),
      link: 'contracts',
      img: <FileStackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:bills"),
      link: 'bills',
      img: <ReaderIcon className='w-5 h-5' />
    }
  ]
}

const AssistantOptions = (t) => {
  return [
    {
      nav: t("navigation:main"),
      link: '/',
      img: <CubeIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:invoices"),
      link: '/admin/invoices/sales',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:clients"),
      link: '/admin/clients',
      img: <BackpackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:counterparties"),
      link: '/admin/contractors',
      img: <PersonIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:contracts"),
      link: 'contracts',
      img: <FileStackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:bills"),
      link: 'bills',
      img: <ReaderIcon className='w-5 h-5' />
    },
    {
      nav: t('navigation:specifications'),
      link: '/specifications',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t('reports:report_title'),
      link: '/reports',
      img: <ClipboardIcon className='w-5 h-5' />
    },
  ]
}

const ClientOptions = (t) => {
  return [
    {
      nav: t("navigation:main"),
      link: '/',
      img: <CubeIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:invoices"),
      link: '/admin/invoices/sales',
      img: <FileTextIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:counterparties"),
      link: '/admin/contractors',
      img: <PersonIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:contracts"),
      link: 'contracts',
      img: <FileStackIcon className='w-5 h-5' />
    },
    {
      nav: t("navigation:bills"),
      link: 'bills',
      img: <ReaderIcon className='w-5 h-5' />
    },
    {
      nav: t('navigation:specifications'),
      link: '/specifications',
      img: <FileTextIcon className='w-5 h-5' />
    }
  ]
}

const getOptionsForRole = (role, t) => {
  switch (role) {
    case ROLES.SUPER_ADMIN:
      return SuperAdmin(t);
    case ROLES.ADMIN:
      return SuperAdmin(t);
    case ROLES.CLIENT:
      return ClientOptions(t);
    case ROLES.ASSISTANT:
      return AssistantOptions(t);
    case ROLES.HEAD_OF_DEPARTMENT:
      return HeadOfDepartmentOptions(t);
    default:
      return []
  }
}

export default function PersistentDrawerLeft(props) {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, showBar } = props;
  const [open, setOpen] = useState(() => {
    const storedValue = localStorage.getItem("openSideBar");
    return storedValue !== null ? storedValue === 'true' : true;
  });
  const [activeItem, setActiveItem] = useState('');
  const options = userData ? getOptionsForRole(userData.roles, t) : [];
  const [videoUrl, setVideoUrl] = useState('');
  const Tooltip = ({ text, children }) => {
    return (
      <div className="relative inline-block group">
        {children}
        <div className="hidden group-hover:block absolute bg-gray-800 text-white left-14 top-0 text-xs py-1 px-2 rounded-md shadow-md z-10 whitespace-nowrap">
          {text}
          <svg className="absolute text-gray-800 h-2 w-full top-full" x="0px" y="0px" viewBox="0 0 255 255">
            <polygon className="fill-current" points="0,0 127.5,1.5 255,0" />
          </svg>
        </div>

      </div>
    );
  };

  const handleItemClick = (link) => {
    setActiveItem(link);
  };

  useEffect(() => {
    setLang(i18n.language);
    setVideoUrl(
      i18n.language === 'ru'
        ? 'https://www.youtube.com/embed/tTTA8Fs6waU'
        : 'https://www.youtube.com/embed/tTTA8Fs6waU'
    );
  }, [i18n.language]);

  const changeSideBarWidth = () => {
    setOpen(prevOpen => {
      const newOpen = !prevOpen;
      localStorage.setItem("openSideBar", newOpen);
      return newOpen;
    });
  }

  useEffect(() => {
    const storedValue = localStorage.getItem("openSideBar");
    if (storedValue === null) {
      localStorage.setItem("openSideBar", true);
    }
    setOpen(storedValue !== null ? storedValue === 'true' : true);
  }, []);



  return (
    <div className='relative w-full h-full p-0 m-0 color-black'>
      <AppBarForLogin isLogined={props.userData} userData={props.userData} />
      {userData && (
        <div className={`fixed left-0 top-0 z-40  transition-width ${open ? 'w-52' : 'w-20'} p-0 m-0 h-screen bg-white border-r-[1px] mr-2`}>
          <div className={`absolute top-5 left-5 cursor-pointer flex justify-between items-center ${open ? 'pl-3' : ''} w-5/6`}
            onClick={() => changeSideBarWidth(open)}>
            <div className='flex justify-between items-center'>
              <div className='flex justify-center items-center'>
                <img className={`${!open ? "h-[18px]" : ""}`} src={logo} alt="logo" />
              </div>
              {!open && (
                <div className='pl-[4px]'>
                  <img className='rotate-180' src={arrowLeft} alt="arrow" />
                </div>
              )}
            </div>
            <div>
              {open && <img src={arrowLeft} alt="arrow" />}
            </div>
          </div>
          <div className='pt-28 flex flex-col justify-center items-start'>
            {options.map((item, index) => (
              <Link to={`${item.link}`} className={`p-4 w-5/6 mx-auto mb-2 rounded-md cursor-pointer hover:text-blueLink ${activeItem === item.link ? 'bg-blueLightAccent text-blueLink' : ''}`}
                key={index}
                data-role={item.nav}
                onClick={() => handleItemClick(item.link)}
              >
                <div className={`flex ${open ? 'justify-start' : 'justify-center'} items-center`}>
                  {open ? (
                    <div>{item.img}</div>
                  ) : (
                    <Tooltip text={item.nav}>
                      <div>{item.img}</div>
                    </Tooltip>
                  )}
                  <div className={`${open ? '' : 'hidden'} pl-2 `}>{item.nav}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      <div className={userData ? (`transition-margin-left ${open ? 'ml-52' : 'ml-20'} mt-12`) : 'w-full m-0 p-0'}>
        {userData ? userData.roles === 'CLIENT' && (
          <AlertBanner
            text={t('banner_text')} />
        ) : null}
        {props.children}
        {userData ? userData.roles === 'CLIENT' && (
          <VideoPopup
            videoUrl={videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
}
